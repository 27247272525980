<template>
  <div class="user-content">
    <el-scrollbar class="u-scroll">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="dataRule"
        label-width="120px"
        label-position="left"
        class="orgApply-form"
      >
        <div class="bold">
          <h2>单位证件认证</h2>
        </div>
        <el-form-item label="企业单位名称" prop="fullName">
          <el-input
            v-model="dataForm.fullName"
            placeholder="输入名称"
            @change="checkUnits"
          />
        </el-form-item>

        <el-form-item label="企业证件类型" prop="certType">
          <el-select v-model="dataForm.certType" placeholder="请选择证件类型">
            <el-option
              v-for="item in certTypeList"
              :key="item.fullName"
              :label="item.fullName"
              :value="item.fullName"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="证件号码" prop="code">
          <el-input
            v-model="dataForm.code"
            placeholder="输入统一社会信用代码"
            @input="getTypeLevel"
            maxlength="18"
          />
        </el-form-item>

        <el-form-item label="单位类型" prop="orgType">
          <el-input v-model="dataForm.orgType" :disabled="true" />
        </el-form-item>
        <!--          <el-form-item label="单位级别" prop="level">-->
        <!--            <el-input v-model="dataForm.level" :disabled="true"/>-->
        <!--          </el-form-item>-->
        <el-form-item label="单位级别" prop="level">
          <el-select v-model="dataForm.level" placeholder="请选择单位级别">
            <el-option
              v-for="item in levelList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="体育项目"
          prop="unitSportEntities"
          v-if="dataForm.orgType == '社团组织'"
        >
          <el-select
            v-model="dataForm.unitSportEntities"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in sportItemList"
              :key="item.id"
              :label="item.fullName"
              :value="item.enCode"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="sportValue"
            placeholder="请输入"
            style="width: 200px; margin-left: 10px"
          />
          <el-button
            type="primary"
            size="medium"
            :disabled="!sportValue"
            style="margin-left: 10px"
            @click="addDictionaryData"
            >添加</el-button
          >
        </el-form-item>

        <el-form-item label="地区" prop="province">
          <el-cascader
            size="large"
            :options="options"
            v-model="selectedOptions"
            :placeholder="
              selectedOptions.length == 0 ? '请选选择地区' : dataForm.province
            "
            @change="handleChange"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="地址" prop="address">
          <el-input v-model="dataForm.address" placeholder="请输入地址" />
        </el-form-item>

        <el-form-item label="租户类型" prop="tenantType">
          <el-radio-group v-model="dataForm.tenantType">
            <el-radio :label="1">群体</el-radio>
            <el-radio :label="2">竞体</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单位介绍">
          <el-input type="textarea" rows="4" v-model="dataForm.shortName" placeholder="请输入单位介绍" style="width: 555px;"/>
        </el-form-item>
        <el-form-item label="官方网站">
          <el-input v-model="dataForm.domainName" placeholder="请输入官方网站(例如：https://www.baidu.com)" />
        </el-form-item>

        <el-form-item label="上传证件附件" prop="imgUrl">
          <div class="el-upload__tip authorization">
            <p>（只能上传jpg/png/jepg文件，且不超过10M）</p>
            <p>
              可以添加“松冬体育服务认证案”等水印；但不能遮挡关键信息，例如企业名称、公司证件号。
            </p>
          </div>
          <el-upload
            class="avatar-uploader"
            :action="uploadImg()"
            :headers="uploadHeaders()"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-popover
              placement="right"
              trigger="hover"
              v-if="dataForm.imgUrl"
            >
              <img :src="imgUrl" class="large" style="width: 600px" />
              <img slot="reference" :src="imgUrl" class="avatar" />
            </el-popover>
            <i v-else class="el-icon-plus avatar-uploader-icon uploadIcon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="上传单位标识" prop="logo">
          <el-upload
            :action="uploadImg()"
            :headers="uploadHeaders()"
            :show-file-list="false"
            :on-success="handleLogoSuccess"
            :before-upload="beforeAvatarUploadTwo"
            class="logo-upload"
          >
            <img
              v-if="dataForm.logo"
              :src="loadUrl(dataForm.logo)"
              class="logo"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon uploadIcon"></i>
          </el-upload>
          <div class="tip">
            上传照片格式要求：jpg、jpeg、bmp、png，不超过10M，建议尺寸（292*150）。
          </div>
        </el-form-item>

        <div class="bold">
          <h2>身份认证</h2>
        </div>
        <el-form-item label="您的身份" prop="personType">
          <el-radio-group v-model="dataForm.personType">
            <el-radio :label="1">法人代表</el-radio>
            <el-radio :label="2">被授权人</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="法定代表人"
          prop="corporateName"
          v-if="dataForm.personType == 2"
        >
          <el-input
            v-model="dataForm.corporateName"
            placeholder="输入法定代表人姓名"
          />
        </el-form-item>

        <el-form-item
          label="法定身份证号"
          prop="corporateId"
          v-if="dataForm.personType == 2"
        >
          <el-input
            v-model="dataForm.corporateId"
            placeholder="输入法定代表人身份证号"
            max="18"
          />
        </el-form-item>

        <el-form-item label="联系人" prop="name">
          <el-input v-model="dataForm.name" placeholder="输入联系人" max="11" />
        </el-form-item>

        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="dataForm.phone"
            placeholder="输入联系电话"
            maxLength="11"
          />
        </el-form-item>

        <el-form-item
          label="上传授权附件"
          prop="certImg"
          v-if="dataForm.personType == 2"
        >
          <div class="el-upload__tip authorization">
            <p>（只能上传jpg/png/jepg文件，且不超过10M）</p>
            <p>
              <a :href="downloadUrl" class="download" @click="download"
                >下载模板</a
              >，授权书需盖单位公章。
              <el-tooltip
                class="remark"
                effect="dark"
                content="您也可以上传含有公司信息的被授权人名片或被授权人工卡替代授权书"
                placement="right"
              >
                <span>无授权书？</span>
              </el-tooltip>
            </p>
          </div>
          <el-upload
            class="avatar-uploader"
            :action="uploadImg()"
            :headers="uploadHeaders()"
            :show-file-list="false"
            :on-success="handleAvatarSuccessCert"
            :before-upload="beforeAvatarUpload"
          >
            <el-popover
              placement="right"
              trigger="hover"
              v-if="dataForm.certImg"
            >
              <img :src="certImg" class="large" style="width: 600px" />
              <img slot="reference" :src="certImg" class="avatar" />
            </el-popover>
            <i v-else class="el-icon-plus avatar-uploader-icon uploadIcon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传法人证书" prop="certImg" v-else>
          <div class="el-upload__tip authorization">
            <p>（只能上传jpg/png/jepg文件，且不超过10M）</p>
          </div>

          <el-upload
            class="avatar-uploader"
            :action="uploadImg()"
            :headers="uploadHeaders()"
            :show-file-list="false"
            :on-success="handleAvatarSuccessCert"
            :before-upload="beforeAvatarUpload"
          >
            <el-popover
              placement="right"
              trigger="hover"
              v-if="dataForm.certImg"
            >
              <img :src="certImg" class="large" style="width: 600px" />
              <img slot="reference" :src="certImg" class="avatar" />
            </el-popover>
            <i v-else class="avatar-uploader-icon el-icon-circle-plus"></i>
          </el-upload>
        </el-form-item>
        <el-divider></el-divider>
        <el-checkbox v-model="checked">
          <span class="checkbox-content"
            >请务必提供真实信息，我们会审查您提供的身份信息是否真实、有效。若提供虚假信息，由此带来的全部结果由您承担。</span
          >
        </el-checkbox>
        <el-form-item class="applyOrgBtn">
          <el-button type="primary" @click="handleConfirm" :disabled="!checked"
            >提交</el-button
          >
          <el-button @click="goBack">取消</el-button>
        </el-form-item>
      </el-form>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  applyUnit,
  uploadAvatar,
  getApplyUnitList,
  checkUnit,
  updateUnit,
  download,
} from "@/api/certify";
import { getUserInfo } from "@/api/user";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { dict, addDictionaryData } from "@/api/dict";

export default {
  data() {
    return {
      checked: false,
      visible: false,
      btnLoading: false,
      formLoading: false,
      canCertify: true,
      imgUrl: "",
      certImg: "",
      itemId: null,
      dataForm: {
        fullName: "", //企业单位名称
        certType: "", //企业证件类型统一社会信用代码、企业营业执照、组织机构代码证、事业单位法人证书、社会团体法人登记证书、行政执法主体资格证）
        code: "", //统一社会信用代码
        orgType: "", //单位类型（机关单位、事业单位、企业、社团组织）
        imgUrl: "", //上传的证件
        personType: "2", //法人1 被授权人2
        corporateName: "", //法人姓名
        corporateId: "", //法人身份证
        certImg: "", //授权证书
        level: "", //单位级别（省、市、区）
        name: "", //联系人
        phone: "", //联系电话
        province: "", //行政区划（省/市/区）
        provinceCode:"",//
        address: "", //地址
        unitSportEntities: [], //体育项目（单位类型为“社团组织”才需填写,多选
        userId: "", //用户id
        logo: null,
        tenantType: 1,
        shortName:'',//单位介绍
        domainName:'',//官方网站
      },
      certTypeList: [],

      sportItemList: [
        { sportName: "足球", sportCode: "1" },
        { sportName: "篮球", sportCode: "2" },
        { sportName: "马术", sportCode: "3" },
        { sportName: "武术", sportCode: "4" },
      ],
      levelList: ["省级", "市级", "区级"],
      downloadUrl: "",
      options: regionData,
      selectedOptions: [],
      dataRule: {
        certType: [
          { required: true, message: "证件类型不能为空", trigger: "input" },
        ],
        fullName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          // {validator: this.formValidate('fullName', '公司名称不能含有特殊符号'), trigger: 'blur'},
          { max: 50, message: "公司名称最多为50个字符！", trigger: "blur" },
        ],
        imgUrl: [{ required: true, message: "请上传证件", trigger: "input" }],
        personType: [
          {
            required: true,
            message: "请输入法定代表人身份证!",
            trigger: "input",
          },
        ],
        corporateName: [
          {
            required: true,
            message: "请输入法定代表人姓名!",
            trigger: "input",
          },
        ],
        corporateId: [
          {
            pattern:
              /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,
            message: "请输入有效的身份证号",
            trigger: "blur",
          },
          {
            required: true,
            len: 18,
            message: "请正确输入身份证号码",
            trigger: "input",
          },
        ],
        level: [{ required: true, message: "请选择单位级别", trigger: "blur" }],
        orgType: [
          { required: true, message: "请填写单位类型", trigger: "blur" },
        ],
        certImg: [
          { required: true, message: "请上传授权证书", trigger: "input" },
        ],
        code: [
          // {required: true, message: '请填写统一社会信用代码', trigger: 'input'},
          {
            required: true,
            len: 18,
            message: "请填写正确的社会统一信用代码",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请填写联系人", trigger: "input" }],
        phone: [
          { required: true, message: "请填写联系电话", trigger: "input" },
          {
            required: true,
            len: 11,
            message: "请输入正确的联系方式",
            trigger: "input",
          },
          {
            pattern: /^1[3456789]\d{9}$|^0\d{2,3}-?\d{7,8}$/,
            message: "请输入正确的联系方式",
            trigger: "blur",
          },
        ],
        province: [
          { required: true, message: "请选择行政区域", trigger: "input" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "input" }],
        unitSportEntities: [
          { required: true, message: "请选择体育项目", trigger: "blur" },
        ],
        logo: [{ required: true, message: "上传单位标识", trigger: "input" }],
        tenantType: [
          { required: true, message: "请选择租户类型", trigger: "change" },
        ],
      },
      imgdialogVisible: false,
      sportValue: "",
    };
  },
  mounted() {
    this.init();
    dict("965dea62840544c983f751dac0912c7e").then((res) => {
      if (res.code === 200) {
        this.certTypeList = res.data.list;
        console.log(this.certTypeList);
      }
    });
    this.getOps();
  },
  methods: {
    getOps() {
      dict("7d2fca183a3e41908c135b9711da0c81").then((res) => {
        if (res.code === 200) {
          this.sportItemList = res.data.list;
          console.log(this.sportItemList);
        }
      });
    },
    init() {
      this.itemId = this.$route.query.id || null;
      if (this.itemId) {
        let param = {
          currentPage: "1",
          pageSize: "20",
          id: this.itemId,
        };
        console.log("this.itemId", this.itemId);
        getApplyUnitList(param)
          .then((res) => {
            this.dataForm = res.data.list[0];
            this.dataForm.personType = res.data.list[0].personType.toString();
            res.data.list[0].unitSportEntities.map((item, index) => {
              this.dataForm.unitSportEntities[index] =
                res.data.list[0].unitSportEntities[index].sport_code;
            });
            this.imgUrl = this.loadUrl(res.data.list[0].imgUrl);
            this.certImg = this.loadUrl(res.data.list[0].certImg);
            let provinces = res.data.list[0].province.split("/")[0];
            let city = res.data.list[0].province.split("/")[1];
            let area = res.data.list[0].province.split("/")[2];
            this.selectedOptions.push(
              TextToCode[provinces].code,
              TextToCode[provinces][city].code,
              TextToCode[provinces][city][area].code
            );
          })
          .catch(() => {
            this.formLoading = false;
          });
      } else {
        getUserInfo().then((res) => {
          if (res.code === 200) {
            this.dataForm.userId = res.data.id;
            if (this.dataForm.userId) {
              let param = {
                currentPage: "1",
                pageSize: "20",
                userId: this.dataForm.userId,
              };

              getApplyUnitList(param)
                .then((res) => {
                  this.canCertify = res.data.list.length <= 0;
                })
                .catch(() => {
                  this.formLoading = false;
                });
            }
          }
        });
      }
    },
    // 新增体育项目数据字典
    addDictionaryData() {
      let params = {
        dictionaryTypeId: "7d2fca183a3e41908c135b9711da0c81",
        fullName: this.sportValue,
        enCode: this.done(6, 9),
        parentId: "0",
        enabledMark: 1,
      };
      addDictionaryData(params).then((res) => {
        this.sportValue = "";
        this.getOps();
        this.dataForm.unitSportEntities.push(params.enCode);
      });
    },
    done(len, max) {
      var arr = [];
      var result = "";
      var count = 0;
      while (count < len) {
        var n = Math.floor(Math.random() * max + 1);
        if (arr.join().indexOf(n) == -1) {
          arr.push(n);
          count++;
        }
      }
      for (let index = 0; index < arr.length; index++) {
        result = result + arr[index];
      }
      return result;
    },

    goBack() {
      this.$refs["dataForm"].resetFields();
    },

    //提交表单
    handleConfirm() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.sportItemList.map((item) => {
            this.dataForm.unitSportEntities.map((code, index) => {
              if (code == item.enCode) {
                this.dataForm.unitSportEntities[index] = {
                  sportName: item.fullName,
                  sportCode: item.enCode,
                };
              }
            });
          });

          // if (this.itemId) {
          //   this.$set(this.dataForm, 'id', this.itemId)
          //   this.$set(this.dataForm,'applyState',0)
          //   updateUnit(this.dataForm).then((res) => {
          //     if (res.code === 200) {
          //       this.$message({
          //         message: "申请成功",
          //         type: "success",
          //         duration: 1500,
          //         onClose: () => {
          //           this.visible = false;
          //           this.btnLoading = false;
          //           this.$emit("close", true);
          //         },
          //       });
          //       this.$router.push("/my-certification");
          //     }
          //   })
          // } else {
          applyUnit(this.dataForm)
            .then((res) => {
              if (res.code === 200) {
                window.localStorage.setItem("applyId", res.msg);
                this.canCertify = false;
                this.$message({
                  message: "申请成功",
                  type: "success",
                  duration: 3000,
                  onClose: () => {
                    this.visible = false;
                    this.btnLoading = false;
                    this.$emit("close", true);
                  },
                });
                this.$router.push("/my-certification");
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.btnLoading = false;
                    this.$emit("close", true);
                  },
                });
                this.$router.push("/my-certification");
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }

        // }
      });
    },

    //上传证书
    handleAvatarSuccess(res) {
      this.dataForm.imgUrl = res.data.url;
      this.imgUrl = this.loadUrl(res.data.url);
    },
    //上传授权书
    handleAvatarSuccessCert(res) {
      this.dataForm.certImg = res.data.url;
      this.certImg = this.loadUrl(res.data.url);
    },
    handleLogoSuccess(res) {
      this.dataForm.logo = res.data.url;
    },
    beforeAvatarUploadTwo(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传头像图片格式错误!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传头像图片格式错误!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    //选择地区
    handleChange(value) {
      console.log(value);
      let self = this;
      let provinceCode = CodeToText[self.selectedOptions[0]].concat("/");
      let cityCode = CodeToText[self.selectedOptions[1]].concat("/");
      let countyCode = CodeToText[self.selectedOptions[2]];

      // CodeToText属性是区域码，属性值是汉字 CodeToText['110000']输出北京市
      self.dataForm.province = provinceCode.concat(cityCode, countyCode);
      self.dataForm.provinceCode = value[0]+'/'+value[1]+'/'+value[2]
    },
    //根据社会信用代码去判断类型和等级
    getTypeLevel() {
      this.dataForm.level = "";

      let type = this.dataForm.code.substring(0, 2);
      let province = this.dataForm.code.substring(2, 4);
      let city = this.dataForm.code.substring(4, 6);
      let district = this.dataForm.code.substring(6, 8);

      switch (type) {
        case "11":
          this.dataForm.orgType = "机关单位";
          break;
        case "12":
          this.dataForm.orgType = "事业单位";
          break;
        case "51":
          this.dataForm.orgType = "社团组织";
          break;
        case "91":
          this.dataForm.orgType = "企业";
          break;
        default:
          this.dataForm.orgType = "其他";
      }

      if (district != "00" && district !== "") {
        this.dataForm.level = "区级";
      } else if (city != "00" && district !== "") {
        this.dataForm.level = "市级";
      } else if (province !== "") {
        this.dataForm.level = "省级";
      }
    },

    //检查认证企业是否存在
    checkUnits() {
      if (this.dataForm.fullName !== "") {
        checkUnit(this.dataForm.fullName).then((res) => {
          if (res.msg !== "未注册") {
            this.$message({
              message: "请勿重复认证",
              type: "warning",
            });
            this.dataForm.fullName = "";
          }
        });
      }
    },

    //下载模板
    download() {
      this.downloadUrl = this.loadUrl(
        "DEFAULT/template/enterprise-certification/example.docx"
      );
      console.log(this.downloadUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-content {
  border: 20px solid #f0f0f0;
  border-bottom-width: 0;
  background: #fff;
  flex: 1;

  .orgApply-form {
    margin: 20px;

    .bold {
      h2 {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 12px;
        margin-bottom: 30px;
        border-bottom: 1px solid #ececec;
      }
    }

    ::v-deep .el-input {
      width: 555px;
    }

    ::v-deep.avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      background-image: url("../../assets/image/cert.svg");
      background-size: 278px 158px;
      background-repeat: no-repeat;
      background-position: center;
    }
    ::v-deep.avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader .uploadIcon {
      border: 1px dashed #c0ccda;
      border-radius: 4px;
    }
    .el-form-item {
      color: #555555;

      .authorization {
        font-size: 12px;
        color: #999999;
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 328px;
      height: 208px;
      line-height: 360px;
      //text-align: center;
      fill: #c2c2c2;

      :hover {
        fill: #409eff;
      }
    }

    .avatar-uploader-icon :hover {
      fill: #409eff;
    }

    .avatar {
      width: 328px;
      height: 208px;
      display: block;
    }

    .large {
      width: 400px;
      object-fit: contain;
    }

    .applyOrgBtn {
      ::v-deep .el-button {
        width: 84px;
        height: 34px;
        line-height: 9px;
      }

      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
        margin-top: 22px;
      }
    }

    ::v-deep.el-upload__tip {
      margin-top: 0;
    }

    .el-checkbox-group {
      width: 580px;
    }

    .el-checkbox {
      .checkbox-content {
        color: #666666;
      }
    }

    .remark,
    .download {
      color: #409eff;
      cursor: pointer;
    }

    .uploadIcon {
      width: 148px;
      height: 148px;
      cursor: pointer;
      line-height: 146px;
      font-size: 28px;
      color: #8c939d;
    }

    .logo {
      width: 148px;
      height: 148px;
      object-fit: cover;
    }

    .logo-upload {
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      cursor: pointer;
      line-height: 146px;
      vertical-align: top;
    }
  }
}
</style>
